import { css } from '@emotion/react'
import { useCallback, useState } from 'react'

import Button from 'src/components/Button'
import { fontFamilies, TextH3, TextParagraph } from 'src/components/Text'
import COLOR from 'src/constants/color'
import { CRYPTO_ASSETS } from 'src/features/trade/hooks/useTradeFunding'
import { useTradeWithdraw } from 'src/features/trade/hooks/useTradeWithdraw'

/**
 * Constants for the Trade Withdraw page, held in an object to allow for easy
 * access, but should be replaced with i18n strings in a non-demo environment.
 */
export const TRADE_WITHDRAW_TEXT = {
  TITLE: 'Step 4: Withdraw Funds',
  TIPS: [
    'Enter the withdrawal amount and destination address. The system will provide a quote including network fees. Once you confirm the quote, the withdrawal will be executed.',
  ],
  DESCRIPTION: 'Enter the amount you want to withdraw',
  AMOUNT_LABEL: 'Amount',
  ADDRESS_LABEL: 'Destination Address',
  AMOUNT_PLACEHOLDER: 'Enter amount',
  ADDRESS_PLACEHOLDER: 'Enter destination address',
  GET_QUOTE: 'Get Withdrawal Quote',
  EXECUTE: 'Execute Withdrawal',
  QUOTE_DETAILS: 'Withdrawal Quote',
  AMOUNT: 'Amount',
  NETWORK_FEE: 'Network Fee',
  EXPIRES: 'Expires',
  WITHDRAWAL_EXECUTED: 'Withdrawal Executed',
  STATUS: 'Status',
  TRANSACTION_ID: 'Transaction ID',
  ERROR: {
    QUOTE: 'Failed to get withdrawal quote',
    EXECUTE: 'Failed to execute withdrawal',
  },
  DEV_NOTES: {
    QUOTE:
      'Withdrawal quotes are provided by ZeroHash and include network fees',
    EXECUTE:
      'Withdrawals are processed through ZeroHash and may take time to complete',
  },
  SELECT_ASSET: 'Select Asset',
}

/**
 * Trade Withdraw Component Architecture
 * ===================================
 *
 * Component Hierarchy
 * ------------------
 * TradeWithdraw
 *   └─ WithdrawalForm
 *      └─ QuoteDetails
 *         └─ ExecutionResult
 *
 * Data Flow
 * ---------
 * 1. User Input -> Amount & Address
 * 2. Quote Request -> Fee Calculation
 * 3. Quote Confirmation -> Execution
 * 4. Transaction Result Display
 */
export function TradeWithdraw() {
  const [amount, setAmount] = useState('')
  const [address, setAddress] = useState('')
  const [selectedAsset, setSelectedAsset] = useState(CRYPTO_ASSETS[0])
  const {
    isLoading,
    error,
    quote,
    result,
    getWithdrawalQuote,
    executeWithdrawal,
  } = useTradeWithdraw()

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault()
      await getWithdrawalQuote(amount, selectedAsset, address)
    },
    [amount, selectedAsset, address, getWithdrawalQuote],
  )

  const handleExecute = useCallback(async () => {
    if (quote?.id != null && typeof quote.id === 'string') {
      await executeWithdrawal(quote.id)
    }
  }, [quote, executeWithdrawal])

  return (
    <div css={containerStyles}>
      <h2 css={titleStyles}>{TRADE_WITHDRAW_TEXT.TITLE}</h2>

      <div css={assetList}>
        {CRYPTO_ASSETS.map((asset) => (
          <button
            key={asset}
            css={[assetButton, asset === selectedAsset && selectedAssetButton]}
            onClick={() => setSelectedAsset(asset)}
          >
            {asset}
          </button>
        ))}
      </div>

      <form onSubmit={handleSubmit} css={formStyles}>
        <div css={inputGroupStyles}>
          <label css={labelStyles}>{TRADE_WITHDRAW_TEXT.AMOUNT_LABEL}</label>
          <input
            type='text'
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            placeholder={TRADE_WITHDRAW_TEXT.AMOUNT_PLACEHOLDER}
            css={inputStyles}
          />
        </div>

        <div css={inputGroupStyles}>
          <label css={labelStyles}>{TRADE_WITHDRAW_TEXT.ADDRESS_LABEL}</label>
          <input
            type='text'
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            placeholder={TRADE_WITHDRAW_TEXT.ADDRESS_PLACEHOLDER}
            css={inputStyles}
          />
        </div>

        <Button
          type='submit'
          disabled={isLoading}
          variant='alternate-inverted'
          size='small'
          css={buttonStyles}
        >
          {TRADE_WITHDRAW_TEXT.GET_QUOTE}
        </Button>
      </form>
      {error != null && (
        <TextParagraph css={errorStyles}>{error}</TextParagraph>
      )}
      {quote != null && (
        <div css={quoteStyles}>
          <h3 css={subtitleStyles}>{TRADE_WITHDRAW_TEXT.QUOTE_DETAILS}</h3>
          <div css={quoteDetailsStyles}>
            <TextParagraph css={quoteDetailsStyles}>
              {TRADE_WITHDRAW_TEXT.AMOUNT} {quote.amount}
            </TextParagraph>
            <TextParagraph css={quoteDetailsStyles}>
              {TRADE_WITHDRAW_TEXT.NETWORK_FEE} {quote.networkFee}
            </TextParagraph>
          </div>
          <Button
            onClick={handleExecute}
            disabled={isLoading}
            variant='alternate-inverted'
            size='small'
            css={buttonStyles}
          >
            {TRADE_WITHDRAW_TEXT.EXECUTE}
          </Button>
          <TextParagraph css={devNoteStyles}>
            {TRADE_WITHDRAW_TEXT.DEV_NOTES.QUOTE}
          </TextParagraph>
        </div>
      )}

      {result && (
        <div css={resultStyles}>
          <TextH3 css={subtitleStyles}>
            {TRADE_WITHDRAW_TEXT.WITHDRAWAL_EXECUTED}
          </TextH3>
          <TextParagraph css={quoteDetailsStyles}>
            {TRADE_WITHDRAW_TEXT.STATUS} {result.status}
          </TextParagraph>
          <TextParagraph css={quoteDetailsStyles}>
            {TRADE_WITHDRAW_TEXT.TRANSACTION_ID} {result.networkTxId}
          </TextParagraph>
          <TextParagraph css={devNoteStyles}>
            {TRADE_WITHDRAW_TEXT.DEV_NOTES.EXECUTE}
          </TextParagraph>
        </div>
      )}
    </div>
  )
}

const containerStyles = css`
  max-width: 400px;
  margin: 0 auto;
  padding: 24px;
  background: ${COLOR.WHITE_ALPHA_50};
  border-radius: 12px;
`

const titleStyles = css`
  color: ${COLOR.WHITE};
  font-size: 24px;
  margin-bottom: 24px;
  font-family: ${fontFamilies};
`

const formStyles = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const inputGroupStyles = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const labelStyles = css`
  color: ${COLOR.WHITE};
  font-size: 14px;
  font-family: ${fontFamilies};
`

const inputStyles = css`
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid ${COLOR.WHITE_ALPHA_100};
  background: transparent;
  color: ${COLOR.WHITE};
  font-size: 16px;
  font-family: ${fontFamilies};

  &::placeholder {
    color: ${COLOR.WHITE_ALPHA_400};
  }
`

const buttonStyles = css`
  width: 100%;
  margin-top: 8px;
`

const errorStyles = css`
  color: ${COLOR.RED_500};
  font-size: 14px;
  margin-top: 16px;
`

const quoteStyles = css`
  margin-top: 24px;
  padding: 16px;
  background: ${COLOR.BLACK};
  border-radius: 8px;
`

const resultStyles = css`
  margin-top: 24px;
  padding: 16px;
  background: ${COLOR.BLACK};
  border-radius: 8px;
`

const subtitleStyles = css`
  color: ${COLOR.WHITE};
  font-size: 18px;
  margin-bottom: 16px;
  font-family: ${fontFamilies};
`

const quoteDetailsStyles = css`
  color: ${COLOR.WHITE};
  font-size: 14px;
  margin-bottom: 16px;

  p {
    margin-bottom: 8px;
    font-family: ${fontFamilies};
  }
`

const devNoteStyles = css`
  color: ${COLOR.PURPLE_100};
  font-size: 14px;
  margin-top: 12px;
  font-style: italic;
`

const assetList = css`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
  width: 100%;
  margin-bottom: 24px;
`

const assetButton = css`
  padding: 12px;
  border-radius: 8px;
  width: 22%;
  border: 1px solid ${COLOR.WHITE_ALPHA_100};
  background: transparent;
  color: ${COLOR.WHITE};
  font-family: ${fontFamilies};
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: ${COLOR.WHITE_ALPHA_50};
  }
`

const selectedAssetButton = css`
  background: ${COLOR.WHITE_ALPHA_100};
  border-color: ${COLOR.WHITE};
`
