/* eslint-disable react/jsx-no-literals */
import { css } from '@emotion/react'
import { useEffect, useMemo, useState } from 'react'
import ZeroHashSDK, { AppIdentifier, IncomingMessageType } from 'zh-web-sdk'

import Button from 'src/components/Button'
import { ExternalLink } from 'src/components/Link'
import { TextParagraph } from 'src/components/Text'
import COLOR from 'src/constants/color'
import { TradeCard } from 'src/features/trade/components/DemoCard'
import { TradeUserStatus, useTrade } from 'src/features/trade/hooks/useTrade'
import { descriptionText } from 'src/features/trade/styles/DemoStyles'
import { ZERO_HASH_APPS_URL } from 'src/features/trade/utils/constants'

/**
 * This text is used exclusively within demo components, not available to users.
 * Migrate all translations to the i18n library for production use.
 */
export const SETUP_ACCOUNT_TITLE = 'Step 1: Onboard with ZeroHash'
export const SETUP_ACCOUNT_CHECK_STATUS = 'Check Status'

export const SETUP_ACCOUNT_TIPS = [
  <>
    All Vault API users must first onboard with ZeroHash. This process involves:
    <ol>
      <li>
        Making an entry in the TradeUser table to link with the Vault API User
        table
      </li>
      <li>
        Registering the user's email with ZeroHash to create an onboarding token
      </li>
      <li>
        Using the token to initialize the ZeroHash SDK and complete the
        onboarding process
      </li>
    </ol>
  </>,
  <>
    To complete a valid onboarding in a CERT (test) environment, you must use
    the following KYC data from{' '}
    <ExternalLink
      href='https://docs.zerohash.com/docs/test-verification-sessions'
      target='_blank'
      rel='noopener noreferrer'
    >
      ZeroHash documentation on test verification sessions
    </ExternalLink>
    :
    <ul>
      <li>First name: Leslie</li>
      <li>Last name: Knope</li>
      <li>Date of birth: 01/18/1975</li>
      <li>Address: 123 Main St. Pawnee, Indiana 46001 USA</li>
      <li>Phone: +1 234-567-8909</li>
      <li>Citizenship: United States</li>
      <li>SSN: 123-45-6789</li>
    </ul>
  </>,
]

/**
 * This text is used exclusively within demo components, not available to users.
 * Migrate all translations to the i18n library for production use.
 */
export const SETUP_ACCOUNT_TEXT = {
  NOT_STARTED: {
    title: 'Setup your account',
    description:
      'Powered by our partner ZeroHash. Set up your account to get the best rates on your crypto purchases',
    buttonText: 'Start Setup',
  },
  INCOMPLETE: {
    title: 'Finish setup',
    description:
      'Complete the account setup process to start using our buy/sell feature.',
    buttonText: 'Continue',
  },
  PENDING: {
    title: 'Account pending',
    description:
      "You'll be notified when your account is ready, usually within 24-48 hours.",
    buttonText: 'Check Status',
  },
  REJECTED: {
    title: 'Setup failed. Take action.',
    description:
      'Your identity verification failed. Please re-submit valid information.',
    buttonText: 'Continue',
  },
  SUSPENDED: {
    title: 'Your account has been suspended',
    description: 'Please contact support for more information.',
    buttonText: 'Continue',
  },
  CLOSED: {
    title: 'Your account has been closed',
    description: 'Please contact support for more information.',
    buttonText: 'Continue',
  },
  READY: {
    title: 'Your account has been approved',
    description: 'Take the next step and make your first transaction today.',
    buttonText: 'Continue',
  },
  ACTIVE: {
    title: 'Your account is active',
    description: 'You can now start trading.',
    buttonText: 'Continue',
  },
}

const AGREE_TO_TERMS_TEXT = 'Agree to Terms'
const AGREEMENT_REQUIRED_TEXT =
  'To begin trading, you must first agree to our terms and conditions.'

export default function SetupAccount() {
  const { onboardingState } = useTrade()
  const {
    status,
    onGetToken,
    error,
    initialLoading,
    canStartOnboarding,
    checkTradeUser,
    hasAgreedToTerms,
    agreeToTerms,
  } = onboardingState
  const [loading, setLoading] = useState<boolean>(false)

  const onStartOnboarding = async () => {
    setLoading(true)
    const token = await onGetToken()

    if (token == null) {
      // If token is null, the onboarding failed. The error is set in the hook.
      setLoading(false)
      return
    }

    sdk.openModal({
      appIdentifier: AppIdentifier.ONBOARDING,
      jwt: token,
    })

    setLoading(false)
  }

  /**
   * Initialize SDK instance with ZeroHash test environment URL
   *
   * An event listener must be attached to the window to handle messages from the ZeroHash iframe
   * according to the ZeroHash SDK documentation. Memoizing the SDK instance ensures that the event
   * listener is only attached once.
   */
  const sdk = useMemo(
    () =>
      new ZeroHashSDK({
        zeroHashAppsURL: ZERO_HASH_APPS_URL,
      }),
    [],
  )

  // Listen for messages from ZeroHash iframe to handle modal closing
  useEffect(() => {
    // Event handler for messages from ZeroHash iframe
    window.addEventListener(
      'message',
      (message: { data: { type: string } }) => {
        // Close modal when user clicks the close button in the ZeroHash UI
        if (
          message.data.type === IncomingMessageType.OnboardingCloseButtonClicked
        ) {
          sdk.closeModal(AppIdentifier.ONBOARDING)
        }
      },
    )

    // Cleanup event listener on component unmount
    // TODO: Replace empty callback with actual message handler
    return () => {
      window.removeEventListener('message', () => {})
    }
  }, [sdk])

  const statusText =
    SETUP_ACCOUNT_TEXT[status.toUpperCase() as keyof typeof SETUP_ACCOUNT_TEXT]

  return (
    <div css={containerStyles}>
      <TradeCard title={statusText.title} description={statusText.description}>
        {!hasAgreedToTerms && canStartOnboarding && (
          <div css={termsContainer}>
            <TextParagraph css={descriptionText}>
              {AGREEMENT_REQUIRED_TEXT}
            </TextParagraph>
            <Button
              size='small'
              variant='alternate-inverted'
              onClick={agreeToTerms}
              loading={loading}
              css={buttonStyles}
            >
              {AGREE_TO_TERMS_TEXT}
            </Button>
          </div>
        )}

        {hasAgreedToTerms && canStartOnboarding && (
          <Button
            size='small'
            variant='alternate-inverted'
            onClick={onStartOnboarding}
            loading={loading || initialLoading}
            css={buttonStyles}
          >
            {statusText.buttonText}
          </Button>
        )}
        {(status === TradeUserStatus.PENDING ||
          status === TradeUserStatus.INCOMPLETE) && (
          <Button
            size='small'
            variant='alternate-inverted'
            onClick={checkTradeUser}
            loading={loading || initialLoading}
          >
            {SETUP_ACCOUNT_CHECK_STATUS}
          </Button>
        )}
      </TradeCard>
      {error != null && (
        <TextParagraph css={errorTextStyles}>{error}</TextParagraph>
      )}
    </div>
  )
}

const containerStyles = css`
  margin: 0 auto;
`

const buttonStyles = css`
  margin-right: 12px;
`

const errorTextStyles = css`
  margin-top: 16px;
  color: ${COLOR.RED_500};
  font-size: 12px;
`

const termsContainer = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
`
