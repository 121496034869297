import { datadogLogs } from '@datadog/browser-logs'
import { useLDClient, withLDProvider } from 'launchdarkly-react-client-sdk'
import { Suspense, lazy, useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import ReactModal from 'react-modal'
import { matchPath } from 'react-router'
import { Redirect, Switch, Route, useLocation } from 'react-router-dom'
import { useQueryParam, StringParam } from 'use-query-params'

import { CenteredSpinningLoader } from 'src/components/Loaders'
import CONFIG from 'src/constants/config'
import { REDACT_KEYWORD } from 'src/constants/log'
import ROUTE, {
  RESTRICTED_ROUTE,
  ENABLE_HUBSPOT_ROUTES,
} from 'src/constants/route'
import { BUNDLE, REDIRECT_QUERY_PARAM } from 'src/constants/route'
import { LOCAL_STORAGE } from 'src/constants/storage'
import { useUserFromToken } from 'src/features/auth/hooks/useAuth'
import useBackendHealthCheck from 'src/hooks/useBackendHealthCheck'
import useGeolocation from 'src/hooks/useGeolocation'
import useScript from 'src/hooks/useScript'
import { useInitializeDiscount } from 'src/hooks/useStoredDiscount'
import AppErrorPage from 'src/pages/AppErrorPage'
import BuyPage from 'src/pages/BuyPage'
import RootToaster from 'src/root/RootToaster'
import { getRedirectRoutes, getInitialRedirect } from 'src/utils/route'
import { sanitizeQueryString } from 'src/utils/url'

const MaintenanceModePage = lazy(() => import('src/pages/MaintenanceModePage'))
const UiLibraryPage = lazy(() => import('src/pages/UiLibraryPage'))
const RootAppCheckout = lazy(() => import('src/root/RootAppCheckout'))
const RootAppConnect = lazy(() => import('src/root/RootAppConnect'))
const RootAppDashboard = lazy(() => import('src/root/RootAppDashboard'))
const RootAppSigning = lazy(() => import('src/root/RootAppSigning'))

ReactModal.setAppElement('#root')

// Init datadog
if (CONFIG.DATADOG_TOKEN != null) {
  datadogLogs.init({
    site: 'datadoghq.com',
    clientToken: CONFIG.DATADOG_TOKEN,
    service: CONFIG.APP_NAME,
    version: CONFIG.APP_VERSION,
    env: CONFIG.APP_ENV,

    beforeSend: (log) => {
      log.view.url = sanitizeQueryString(log.view.url, ['jwt'], REDACT_KEYWORD)
      return true
    },
  })
}

function useInitializeLocalStorage() {
  useInitializeDiscount({
    queryKey: 'ref',
    storageKey: LOCAL_STORAGE.VISITED_REFERRAL,
  })
}

function App(): React.ReactElement {
  useInitializeLocalStorage()

  const ldClient = useLDClient()
  const location = useLocation()

  const [redirectRoutes, setRedirectRoutes] = useState<Record<string, string>>(
    {},
  )

  // inject hubspot support widget on non-sensitive pages
  const isHubSpotRouteEnabled: boolean = ENABLE_HUBSPOT_ROUTES.some((route) =>
    matchPath(location.pathname, {
      path: route,
    }),
  )

  useScript(
    isHubSpotRouteEnabled ? '//js.hs-scripts.com/8271513.js' : undefined,
  )

  const { user, userLoading } = useUserFromToken()
  const { loading: loadingLocation } = useGeolocation()
  const { isMaintenanceMode } = useBackendHealthCheck()

  const [authedRedirect] = useQueryParam(REDIRECT_QUERY_PARAM, StringParam)
  const initialRedirect = getInitialRedirect(location, user, authedRedirect)

  useEffect(() => {
    if (user != null && ldClient != null) {
      void ldClient.identify({
        kind: 'user',
        key: user.id,
        userTier: user.role,
        userType: user.type,
        platform: 'web',
      })
    }
  }, [ldClient, user])

  useEffect(() => {
    setRedirectRoutes(getRedirectRoutes())
  }, [])

  if (userLoading === 'loading' || loadingLocation) {
    return <CenteredSpinningLoader data-testid='app-loader' />
  }

  if (isMaintenanceMode && location.pathname !== ROUTE.MAINTENANCE_MODE) {
    return <Redirect to={ROUTE.MAINTENANCE_MODE} />
  }

  const redirectRoute: string | undefined =
    location.pathname in redirectRoutes
      ? (redirectRoutes[location.pathname] ?? '') + location.search
      : undefined
  if (redirectRoute != null) {
    return <Redirect to={redirectRoute} />
  }

  const restrictedRoute = RESTRICTED_ROUTE[location.pathname as ROUTE]
  if (
    restrictedRoute != null &&
    (user == null || !restrictedRoute.ALLOWED.includes(user.role))
  ) {
    return <Redirect to={restrictedRoute.REDIRECT} />
  }

  if (initialRedirect != null) {
    return <Redirect to={initialRedirect} />
  }

  return (
    <Suspense
      fallback={<CenteredSpinningLoader data-testid='app-bundle-loader' />}
    >
      <ErrorBoundary fallback={<AppErrorPage />} resetKeys={[location.key]}>
        <Switch>
          <Route path={ROUTE.MAINTENANCE_MODE}>
            <MaintenanceModePage />
          </Route>
          <Route path={ROUTE.UI}>
            <UiLibraryPage />
          </Route>
          <Route path={ROUTE.BUY} exact={true}>
            <BuyPage />
          </Route>
          <Route path={BUNDLE.CHECKOUT_ROUTES} exact={true}>
            <RootAppCheckout />
          </Route>
          <Route path={BUNDLE.CONNECT_ROUTES} exact={true}>
            <RootAppConnect />
          </Route>
          <Route path={BUNDLE.DASHBOARD_ROUTES} exact={true}>
            <RootAppDashboard />
          </Route>
          <Route path={BUNDLE.SIGNING_ROUTES} exact={true}>
            <RootAppSigning />
          </Route>
          <Route>
            <RootAppDashboard />
          </Route>
        </Switch>
      </ErrorBoundary>
      <RootToaster />
    </Suspense>
  )
}

export default withLDProvider({
  clientSideID: CONFIG.LAUNCHDARKLY_CLIENTSIDE_ID,
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
})(App)
