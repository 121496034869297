import { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { RESPONSE_STATUS } from 'src/constants/api';
import useApi from 'src/hooks/useApi';
export default function useBackendHealthCheck() {
    const [isHealthy, setIsHealthy] = useState(null);
    const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);
    const [error, setError] = useState(null);
    const { fetch: fetchApplicationStatus, loading } = useApi({ serviceName: 'vault', path: 'application/status' });
    const checkHealth = useCallback(async () => {
        const { data, error: appStatusError, status } = await fetchApplicationStatus();
        if (appStatusError) {
            setError(appStatusError);
            return;
        } // Vault API's application status will remain accessible during maintenance mode (503)
        if (status === RESPONSE_STATUS.OK) {
            setIsHealthy(true);
        }
        else if (status === RESPONSE_STATUS.MAINTENANCE && (data === null || data === void 0 ? void 0 : data.maintenanceMode) === true) {
            setIsMaintenanceMode(true);
            setIsHealthy(false);
        }
    }, [fetchApplicationStatus]);
    useEffect(() => { void checkHealth(); }, [checkHealth]);
    return { isHealthy, isMaintenanceMode, error, loading };
}
